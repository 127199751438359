<template>
    <div>
        <Modal title="通知設定" v-model="isShowModal" width="640px">
            <template v-slot:modal-content>
                <p class="second-title">產險到期提醒</p>
                <p class="depiction">
                    設定所有產險件保單在到期前幾天於網頁上顯示「將到期」標誌
                </p>
                <div class="days-container">
                    <Button
                        v-for="(day, index) in dayConfig"
                        :key="index"
                        :buttonWord="day.label"
                        :width="105"
                        buttonSize="small"
                        :buttonStyle="getButtonStyle(day.value)"
                        @click="getDayValue(day.value)"
                    />
                </div>
                <div class="customize-container">
                    <Button
                        :width="105"
                        buttonSize="small"
                        buttonWord="自訂天數"
                        :buttonStyle="isCustomize ? 'blue' : 'grey'"
                        @click="setCustomizeValue()"
                    />
                    <Textbox
                        type="number"
                        placeholder="請輸入天數"
                        width="120"
                        maxlength="2"
                        v-model="customizeValue"
                        :class="{ showUnit: customizeValue }"
                        :isError="isError"
                        :errorMessage="'天數不在區間內'"
                    />
                </div>
                <div class="remark-container">
                    <p v-for="(note, index) in remarks" :key="index">
                        ・{{ note }}
                    </p>
                </div>
            </template>
            <template v-slot:modal-bottom>
                <Button
                    buttonWord="取消"
                    buttonStyle="grey"
                    @click="turnOffModal()"
                />
                <Button buttonWord="套用" buttonStyle="blue" @click="apply()" />
            </template>
        </Modal>
        <SuccessModal
            v-model="isShowSuccessModal"
            :message="`設定成功，將重新載入保單列表更新到期狀態`"
        />
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import Textbox from '@/components/Textbox.vue'
import SuccessModal from '@/containers/SuccessModal.vue'
import { editTiaInsuranceSettingsAPI } from '@/assets/javascripts/api'

export default {
    name: 'InsuranceReminderSettingModal',
    emits: ['update:modelValue', 'reload'],
    components: {
        Modal,
        Button,
        Textbox,
        SuccessModal
    },
    props: {
        modelValue: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        isLoading: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        hasReminderError: {
            type: Boolean,
            default: false
        },
        remindBeforeDays: {
            type: Number
        },
        manCode: {
            type: Number,
            default: null
        }
    },
    methods: {
        turnOffModal: function () {
            this.isShowModal = false
        },
        apply: async function () {
            if (this.isCustomize && !this.customizeValue) {
                this.isError = true
                return
            }
            if (this.isError) {
                return
            }
            this.$setGaEvent('setRemindBeforeDays', 'click-Button')
            this.$showLoading()
            if (this.isCustomize) this.dayValue = parseInt(this.customizeValue)
            const manCode = this.$store.state?.userInfo?.code ?? this.manCode
            const payload = {
                remindBeforeDays: this.dayValue
            }
            this.$emit('reload')
            try {
                let res = await editTiaInsuranceSettingsAPI(manCode, payload)

                if (res.status !== 200) {
                    throw res.data?.code
                }
                this.isShowSuccessModal = true
                this.$emit('reload')
            } catch (error) {
                this.$hideLoading()
                this.$showErrorMessage(
                    '套用設定時發生錯誤，請稍後再試',
                    '如持續發生此情形，請聯絡資訊客服'
                )
            } finally {
                this.$hideLoading()
                this.turnOffModal()
            }
        },
        getButtonStyle: function (value) {
            if (this.isCustomize) return 'grey'
            else return this.dayValue === value ? 'blue' : 'grey'
        },
        getDayValue: function (value) {
            this.customizeValue = null
            this.isError = false
            this.isCustomize = false
            this.dayValue = value
        },
        setCustomizeValue: function () {
            this.isCustomize = true
            this.dayValue = this.customizeValue
        },
        reset: function () {
            this.dayValue = this.remindBeforeDays
            const isConformOption = this.dayConfig.some(
                (option) => option.value == this.remindBeforeDays
            )
            if (!isConformOption) {
                this.isCustomize = true
                this.customizeValue = this.remindBeforeDays
            } else {
                this.isCustomize = false
                this.customizeValue = null
            }
        }
    },
    computed: {
        isShowModal: {
            get() {
                return this.modelValue
            },
            set(val) {
                this.$emit('update:modelValue', val)
            }
        },
        dayConfig: function () {
            return [
                { label: '7天', value: 7 },
                { label: '14天', value: 14 },
                { label: '21天', value: 21 },
                { label: '30天', value: 30 }
            ]
        },
        remarks: function () {
            return [
                '自訂可輸入天數區間為1~90天。',
                '如需TIA推播，請至TIA以下路徑進行設定(行政支援→帳號設定→開/關產險到期日提示)',
                '若無產險到期日的保單，不會收到TIA推播。',
                '剩餘到期天數等於自訂提醒天數時，當天才會收到TIA推播。'
            ]
        }
    },
    watch: {
        modelValue: function () {
            if (!this.modelValue)
                setTimeout(() => {
                    this.reset()
                }, 500)
            else {
                if (this.hasReminderError)
                    this.$showErrorMessage(
                        '讀取產險到期提醒設定時發生錯誤',
                        '如持續發生此情形，請聯絡資訊客服'
                    )
            }
        },
        remindBeforeDays: function () {
            this.reset()
        },
        customizeValue: function (newVal) {
            if (newVal) {
                const customizeValue = parseInt(this.customizeValue)
                this.isCustomize = true
                if (customizeValue < 1 || customizeValue > 90)
                    this.isError = true
                else this.isError = false
            } else {
                this.isError = this.isCustomize
            }
        }
    },
    data() {
        return {
            isShowSuccessModal: false,
            dayValue: 0,
            customizeValue: null,
            isCustomize: false,
            isError: false
        }
    }
}
</script>

<style lang="scss" scoped>
.second-title {
    font-size: 16px;
    color: $sixth-black;
    font-weight: bold;
    margin-bottom: 5px;
}
.depiction {
    font-size: 14px;
    margin-bottom: 20px;
    color: $sixth-black;
}
.days-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}
.customize-container {
    display: flex;
    margin-bottom: 20px;
    :deep(.input-container) {
        position: relative;
        overflow: unset;
        width: 105px;
        margin-bottom: 0px;
        &.showUnit {
            &::after {
                content: '天';
                position: absolute;
                top: 5px;
                right: 15px;
                width: 12px;
                height: 12px;
                font-size: 14px;
                color: $placeholder-black;
            }
        }
    }
}

.customize-container,
.days-container {
    :deep(.button) {
        margin-right: 10px;
        margin-bottom: 10px;
    }
    :deep(.number) {
        height: 38px;
    }
}

.remark-container {
    margin-bottom: 20px;
    padding: 10px;
    background: $primary-grey;
    :deep(p) {
        color: $sixth-black;
    }
    @media screen and (max-width: 576px) {
        margin-bottom: 0px;
    }
}
</style>
